<template>
  <div class="box">
    <aside class="animate__animated animate__bounceInLeft sidebar">
      <div class="avatar">
        <img src="http://longshifu.top/group1/M00/00/00/fN7UH2LadZWAI8ACAANyW_i7PdQ327.jpg" title="龙">
      </div>
      <nav class="nav">
        <a v-for="intro in introduceList" @click="showSection(intro.name)" :key="intro.id"><i :class="intro.icon" style="margin-right: 10px"></i> {{intro.title}}</a>
      </nav>
    </aside>
    <main>
      <section class="animate__animated animate__fadeInRight" v-if="showSectionId === '#info'" id="info">
        <div class="wrap">
          <h2 class="title"><i class="iconfont icon-gerenzhongxin1"></i> 个人资料</h2>
          <el-row :gutter="20" class="row">
            <el-col :sm="8" :xs="24" class="col-l-4">
              <p><i class="iconfont icon-name" style="margin-right: 5px"></i>姓名：龙师傅</p>
              <p><i class="iconfont icon-sex" style="margin-right: 5px"></i>性别：男</p>
              <p><i class="iconfont icon-nianling" style="margin-right: 5px"></i>年龄：26</p>
              <p><i class="iconfont icon-xueli" style="margin-right: 5px"></i>学历：本科</p>
<!--              <p><i class="iconfont icon-qq1" style="margin-right: 5px"></i>QQ：</p>-->
<!--              <p><i class="iconfont icon-shengao" style="margin-right: 5px"></i>身高：</p>-->
<!--              <p><i class="iconfont icon-icon1" style="margin-right: 5px"></i>体重：52kg</p>-->
<!--              <p><i class="iconfont icon-shouye" style="margin-right: 5px"></i>血型：O型</p>-->
            </el-col>
            <el-col :sm="16" :xs="24" class="col-l-4">
              <p><i class="iconfont icon-xuexiao1" style="margin-right: 5px"></i>学校：XXXX大学</p>
              <p><i class="iconfont icon-zhuanye1" style="margin-right: 5px"></i>专业：物联网工程</p>
              <p><i class="iconfont icon-gitee-fill-round" style="margin-right: 5px"></i>码云仓库：<a href="https://github.com">https://gitee.com/tyoubin_admin</a></p>
              <p><i class="iconfont icon-csdn1" style="margin-right: 5px"></i>CSDN博客：<a href="https://www.cnblogs.com/dragon-lan/">https://www.cnblogs.com/dragon-lan/</a></p>
<!--              <p><i class="iconfont icon-email" style="margin-right: 5px"></i>邮箱：<a href="mailto:2569757226@qq.com">2569757226@qq.com</a></p>-->
            </el-col>
          </el-row>
        </div>
      </section>
      <section class="animate__animated animate__fadeInRight" v-show="showSectionId === '#skills'" id="skills">
        <div class="wrap">
          <h2 class="title"><i class="iconfont icon-zhuanye"></i> 掌握的技能</h2>
          <el-row :gutter="40" class="row scrollable">
            <el-col :sm="8" :xs="24" class="center-fixed">
              <div class="skills-icon">
                <i class="iconfont icon-html"></i>
              </div>
              <div class="skills-title">
                <h3>HTML5</h3>
                <p>熟悉 HTML5 网站的架构和开发</p>
              </div>
            </el-col>
            <el-col :sm="8" :xs="24" class="center-fixed">
              <div class="skills-icon">
                <i class="iconfont icon-css1"></i>
              </div>
              <div class="skills-title">
                <h3>CSS3</h3>
                <p>熟悉使用 CSS3 写响应式网站、动画等</p>
              </div>
            </el-col>
            <el-col :sm="8" :xs="24" class="center-fixed">
              <div class="skills-icon">
                <i class="iconfont icon-logo-javascript"></i>
              </div>
              <div class="skills-title">
                <h3>JavaScript</h3>
                <p>能使用 JS 制作简单的程序、交互与后端数据的获取</p>
              </div>
            </el-col>
            <el-col :sm="8" :xs="24" class="center-fixed">
              <div class="skills-icon">
                <i class="iconfont icon-Vue"></i>
              </div>
              <div class="skills-title">
                <h3>Vue</h3>
                <p>熟悉Vue的开发流程，能够使用Vue框架开发完整项目</p>
              </div>
            </el-col>
            <el-col :sm="8" :xs="24" class="center-fixed">
              <div class="skills-icon">
                <i class="iconfont icon-java"></i>
              </div>
              <div class="skills-title">
                <h3>Java</h3>
                <p>熟悉Java语言的基本使用，能使用SpringBoot进行后端开发</p>
              </div>
            </el-col>
            <el-col :sm="8" :xs="24" class="center-fixed">
              <div class="skills-icon">
                <i class="iconfont icon-shujujiegou-01"></i>
              </div>
              <div class="skills-title">
                <h3>计算机专业基础</h3>
                <p>熟悉基本的数据结构，算法，计算机网络等相关知识</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </section>
      <section class="animate__animated animate__fadeInRight" v-show="showSectionId === '#works'" id="works">
        <div class="wrap">
          <h2 class="title"><i class="iconfont icon-zuopin1"></i>个人作品</h2>
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :lg="8" v-for="work in workList" :key="work.id">
              <div class="works-item">
                <el-card class="works-item-front">
                  <el-image :src="work.pic_url"/>
                  <p>{{work.name}}</p>
                </el-card>
                <el-card class="works-item-back">
                  <p>{{work.desp}}</p>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </div>
      </section>
      <section class="animate__animated animate__fadeInRight" v-show="showSectionId === '#hobbys'" id="hobbys">
          <h2 class="title"><i class="iconfont icon-xingquaihao"></i>我的爱好</h2>
          <div class="row">
            <el-carousel :interval="2000" :type="cardOpen? 'card':''" height="350px">
              <el-carousel-item v-for="hobby in hobbyList" :key="hobby.id">
                <el-card class="hobby">
                  <el-image style="width: 100%" :src="hobby.pic_url"></el-image>
                  <h3 class="tit">{{hobby.name}}</h3>
                  <p>{{hobby.desp}}</p>
                </el-card>
              </el-carousel-item>
            </el-carousel>
        </div>
      </section>
      <section class="animate__animated animate__fadeInRight" v-show="showSectionId === '#summary'" id="summary">
        <div class="wrap">
          <h2 class="title"><i class="iconfont icon-ziwopingjia"></i>自我评价</h2>
          <div class="row">
            <ul>
              <li>啥也不是</li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: document.documentElement.clientWidth,  //实时屏幕宽度,
      showSectionId: '#info',
      introduceList: [
        {
          id: 0,
          title: '资料',
          name: '#info',
          icon:'iconfont icon-zhuanye1'
        },
        {
          id: 1,
          title: '技能',
          name: '#skills',
          icon:'iconfont icon-jinengliang-xian'
        },
        {
          id: 2,
          title: '作品',
          name: '#works',
          icon:'iconfont icon-zuopin'
        },
        {
          id: 3,
          title: '爱好',
          name: '#hobbys',
          icon:'iconfont icon-xingquaihao'
        },
        {
          id: 4,
          title: '评价',
          name: '#summary',
          icon:'iconfont icon-ziwopingjia'
        },
      ],
      workList:[
        {
          id:0,
          name:'个人博客',
          desp:'一个简单的个人博客项目，分为前台展示和后台管理系统，前台展示部分实现了博客的浏览，查找，分类，标签，评论，留言等功能，后台管理系统实现了博客发表，博客管理，用户管理等功能。',
          pic_url:'http://longshifu.top/group1/M00/00/00/fN7UH2LadZWAI8ACAANyW_i7PdQ327.jpg'
        },
        {
          id:1,
          name:'Vue商城管理系统',
          desp:'实现了商城后台管理用户账号(登录，退出，用户管理，权限管理)，商品管理(商品分类，分类参数，商品信息，订单)，数据统计等功能',
          pic_url:'http://longshifu.top/group1/M00/00/00/fN7UH2LadZWAI8ACAANyW_i7PdQ327.jpg'
        },
      ],
      hobbyList:[
        {
          id: 0,
          name:'蓝球',
          pic_url:'http://longshifu.top/group1/M00/00/00/fN7UH2LadZWAI8ACAANyW_i7PdQ327.jpg',
          desp:'蓝球，工作了是真没怎么碰过球了，但村里今年刚建好了篮球场，期待回家。'
        },
        {
          id: 1,
          name:'跑步',
          pic_url:'http://longshifu.top/group1/M00/00/00/fN7UH2LadZWAI8ACAANyW_i7PdQ327.jpg',
          desp:'有氧跑是真的舒服。'
        },
        {
          id: 2,
          name:'军迷',
          pic_url:'http://longshifu.top/group1/M00/00/00/fN7UH2LadZWAI8ACAANyW_i7PdQ327.jpg',
          desp:'若有战，召必回！'
        },
        {
          id: 3,
          name:'看电影',
          pic_url:'http://longshifu.top/group1/M00/00/00/fN7UH2LadZWAI8ACAANyW_i7PdQ327.jpg',
          desp:'比较喜欢科幻片，虽然因为没钱，没在电影院没看过几部，但这也算一个爱好吧'
        },
        {
          id: 4,
          name:'旅游',
          pic_url:'http://longshifu.top/group1/M00/00/00/fN7UH2LadZWAI8ACAANyW_i7PdQ327.jpg',
          desp:'这只能算一个美好的愿望，等我有钱了，要去各种各样的地方玩，体验不一样的生活'
        },
      ],
    }
  },
  computed:{
    cardOpen(){
      return this.screenWidth >= 748;
    }
  },
  created() {
    window.addEventListener('resize', this.screenAdapter)
  },
  methods: {
    showSection(name) {
      this.showSectionId = name
    },
    // 屏幕尺寸变化的监听函数
    screenAdapter(){
      this.screenWidth = document.documentElement.clientWidth;
    }
  }
}
</script>

<style scoped lang="less">
  body {
    height: 100vh;
    overflow: hidden;
  }

  .box {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    color: #fff;
    width: 250px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background: rgba(75, 75, 75, 0.9);

    nav {
      margin: 0;
      display: flex;
      list-style: none;
      flex-direction: column;

      a {
        font: 16px/1.5 'Microsoft Yahei', 'PingFang SC', 'Hiragino Sans GB', sans-serif;
        list-style: none;
        box-sizing: border-box;
        text-decoration: none;
        color: #fff;
        padding: 1em;
        display: block;
        text-align: center;
        transition: background .3s, box-shadow .3s;
      }

      a:hover {
        background: rgba(60, 60, 60, 1);
      }
    }

    .avatar {
      width: 140px;
      height: 140px;
      margin: 1em auto;
      margin-top: 5em;
      background: #fff;
      border-radius: 100%;
      border: #fff 5px solid;
      transition: transform 0.5s;

      img {
        width: 100%;
        border-radius: 50%;
        /*transition: .6s;*/
      }

      img:hover {
        transform: rotate(360deg);
        transition: all 1.5s;
      }
    }
  }

  main {
    color: #353535;
    font: 16px/1.5 'Microsoft Yahei', 'PingFang SC', 'Hiragino Sans GB', sans-serif;
    box-sizing: border-box;
    margin-left: 250px;

    section {
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      background-color: rgba(255, 255, 255, 0.8);
      border: 3px dashed rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      padding: 20px;
      .title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        i{
          font-size: larger;
          margin-right: 10px;
        }
      }
      a{
        text-decoration: none;
      }
    }

    section:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-right: 20px solid rgba(0, 0, 0, 0.3);
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
    }

    #info {
      .warp {
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    #skills {
      .center-fixed {
        text-align: center;
        display: flex;
        flex-direction: column;

        .skills-icon {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          background: #fff;
          border-radius: 100%;
          box-shadow: 0 5px rgba(0, 0, 0, 0.3);

          i {
            font-size: 50px;
            line-height: 100px;
            transition: all 1s;
          }

          .fa-html5 {
            content: "\f13b";
          }
        }

        .skills-icon:hover {
          i {
            transform: rotate(180deg);
            transition: all 1s;
          }
        }

      }

    }
    #works{
      .el-card /deep/ .el-card__body{
        padding: 0;
      }

      .works-item{
        margin-bottom: 20px;
        position: relative;
        .works-item-front,.works-item-back{
          background-color: white;
          text-align: center;
          border-radius: 5px;
          box-shadow: 0 0 5px 0 #ccc;
          border: 1px solid #5d5d5d;
          transition: all 1s;
          backface-visibility: hidden;
          width: 100%;
          height: 100%;
        }
        .works-item-front{
          .el-image{
            max-width: 100%;
            max-height: 180px;
            border-radius: 5px 5px 0 0;
          }
          p{
            margin-top: 2px;
            margin-bottom: 2px;
            color: #303133;
          }
        }
        .works-item-back{
          box-sizing: border-box;
          padding: 20px;
          position: absolute;
          top: 0;
          background: rgba(255, 255, 255, 0.8);
          transform: rotateY(180deg);
        }
      }
      .works-item:hover {
       .works-item-back{
          transform: rotateY(0deg);
        }
        .works-item-front{
          transform: rotateY(180deg);
        }
      }
    }

    #hobbys{
      .el-card /deep/ .el-card__body{
        padding: 0;
        height: 350px;
      }
      .hobby{
        opacity: 0.9;
        background-color: rgba(255,255,255,0.9);
        box-shadow: none;
        margin-bottom: 20px;
        border-radius: 20px;
        .el-image{
          width: 100%;
          max-height: 200px;
        }
        .tit{
          margin: 0 auto;
          line-height: 20px;
          text-align: center;
        }
        p{
          margin: 0;
          padding: 20px;
          font-size: medium;
        }
      }
    }
    #summary{
      ul{
        li{
          line-height: 50px;
          border-bottom: 2px dashed #63a35c;
          background-color: rgba(0,0,0,0.1);
          margin-bottom: 20px;
          border-radius: 9999px;
          list-style-type: circle;
        }
      }
    }
  }


  @media screen and (max-width: 768px){
    .sidebar{
      position: relative;
      top: 0;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .avatar{
        margin: 1rem auto;
        width: 100px;
        height: 100px;
      }
      nav{
        background-color: rgba(255,255,255,0.1);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
    }
    main{
      margin: 0 auto;
      width: 100%;
      text-align: center;
      section{
        border-radius: 0;
        border: none;
      }
      #summary{
        ul{
          li{
            border-radius: 0;
            border-bottom: none;
            background-color: transparent;
            text-align: left;
          }
        }
      }
    }
  }

</style>
